import React from "react";
import { useTranslation } from 'react-i18next';
import {Modal} from 'antd';
import {exportExcel} from "./excelDownload";
import moment from "moment";

export enum ExportSheetType {Input, Output};
type Props = {
  type: ExportSheetType;
}

const ExportSheetButton = (props: Props) => {
  const { t } = useTranslation();

  const download2Excel = async () => {
    const now = moment();
    const localTime = now.format("YYYYMMDDHHmmss");
    Modal.confirm({
      title: t(`instance.confirm.export_${
        props.type === ExportSheetType.Input ? 'input' : 'output'
      }`),
      onOk() {
        exportExcel(
          window.luckysheet.getluckysheetfile(),
          props.type === ExportSheetType.Input ? 'export-input-'+localTime : 'export-output-'+localTime,
        )
      }
    });
  }
  return (
    <button
      type="button"
      className="btn btn-outline-secondary"
      style={{marginLeft: '0.5em'}}
      onClick={download2Excel}>
        {t(`instance.action.export_${
          props.type === ExportSheetType.Input ? 'input' : 'output'
        }`)}
    </button>
  );
}

export default ExportSheetButton;
