import React, {forwardRef, useImperativeHandle, useState, useEffect, useRef, useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';

import {Card, Col, Row, Empty, Space, Collapse, Typography, Image, Modal, Button, Tooltip, Table} from "antd";

import {appRunStats} from "../interfaces/appRunStats";

import ManageLayout from '../components/layouts/ManageLayout';
import { getInstancesApi } from '../api/ServerApi';

import FileIcon from '../common/images/icons/file-icon.png';
import HomeCard1 from '../common/images/home-card-1.png';
import HomeCard2 from '../common/images/home-card-2.png';
import HomeCard3 from '../common/images/home-card-3.png';
import AppIcon1 from '../common/images/icons/app-icon-1.png';
import AppIcon2 from '../common/images/icons/app-icon-2.png';
import AppIcon3 from '../common/images/icons/app-icon-3.png';
import AppIcon4 from '../common/images/icons/app-icon-4.png';
import AppIcon5 from '../common/images/icons/app-icon-5.png';
import Guide1 from '../common/images/guide-1.png';
import Guide2 from '../common/images/guide-2.png';
import Guide3 from '../common/images/guide-3.png';
import Guide4 from '../common/images/guide-4.png';
import Guide5 from '../common/images/guide-5.png';
import Guide6 from '../common/images/guide-6.png';
import CollapseExpandIcon from '../common/images/icons/collapse-expand-icon.png';
import ListViewIcon from '../common/images/icons/list-view-icon.png';
import CardViewIcon from '../common/images/icons/card-view-icon.png';
import LockedIcon from'../common/images/icons/locked-icon.png';

import { getConfig } from '../config/config';
const config = getConfig();

const GuideVideoModal = forwardRef((props, ref) => {
    const [showVideo, setShowVideo] = useState(false);

    useImperativeHandle(ref, () => ({
        show: () => setShowVideo(true)
    }));

    if(!showVideo) {
        return null;
    }

    return (
        <Modal
            width={800}
            centered
            open={showVideo}
            onCancel={() => setShowVideo(false) }
            footer={null}
            closeIcon={null}>
            <div style={{
                paddingBottom: '60%',
                position: 'relative',
                margin: '-16px -20px',
            }}>
                <iframe
                    title='help'
                    src="//player.bilibili.com/player.html?aid=265276073&bvid=BV1dY411y7NF&cid=964953594&page=1&high_quality=1"
                    scrolling="no"
                    border="0"
                    frameborder="no"
                    framespacing="0"
                    allowfullscreen="true"
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0
                    }}>
                </iframe>
            </div>
        </Modal>
    );
});

const HomePage = (props: React.PropsWithRef<any>) => {
    const { t, i18n } = useTranslation();
    const guideVideoModalRef = useRef();
    const history = useHistory();

    const CommonCard = (cardProps: {
        className?: string;
        top?: string;
        cover: any;
        name: string;
        name_link?: string;
        cover_name?: string;
        icon: any;
        tag?: string;
        tag_color?: string;
    }) => {
        return (
            <Card className={cardProps.className || 'home-cover-card'} size='small' bordered={true} cover={
                <div style={{position: 'relative'}}>
                    <Image
                        src={cardProps.cover}
                        preview={false}
                    />
                    <Typography.Text ellipsis={{
                        tooltip: {
                            overlayClassName: 'description_tooltip',
                        }
                    }} style={{
                        position: "absolute",
                        top: cardProps.top || "45%",
                        color: '#fff',
                        textAlign: 'center',
                        width: "100%",
                        left: 0,
                        fontSize: cardProps.top ? '0.95em' : '1em',
                        padding: '0 10px',
                        fontWeight: 700
                    }}>
                        {cardProps.cover_name || cardProps.name}
                    </Typography.Text>
                    {
                        !!cardProps.tag && <div style={{
                            color: "#fff",
                            position: "absolute",
                            right: 0,
                            top: 0,
                            fontSize: "0.85em",
                            fontWeight: 500,
                            padding: 5,
                            background: cardProps.tag_color || '#1890ff'
                        }}>
                            {cardProps.tag}
                        </div>
                    }
                </div>
            }>
                <div className='d-flex align-items-center'>
                    <Image
                        src={cardProps.icon}
                        preview={false}
                        width={'1.2em'}
                    />
                    <Typography.Text
                        ellipsis={{
                            tooltip: {
                                placement: "bottom",
                                overlayClassName: 'description_tooltip',
                            }
                        }}
                        className={`mx-2 ${!!cardProps.name_link ? 'title-link' : ''}`}
                        style={{flex: 1, color: "rgba(0,0,0,0.65)"}}
                        onClick={(e) => {
                            e.stopPropagation();
                            if(!!cardProps.name_link) {
                                history.push(cardProps.name_link);
                            }
                        }}>
                        {cardProps.name}
                    </Typography.Text>
                </div>
            </Card>
        )
    }

    const InstanceCards = () => {
        const [instances, setInstances] = useState([]);
        const [viewType, setViewType] = useState('list');
        useEffect(() => {
            if(!!props.current_workspace_id) {
                (async() => {
                    const res = await getInstancesApi({
                        workspace_id: props.current_workspace_id,
                        page: 1,
                        page_size: 12,
                        active: true,
                        order_by_updated_at: "desc",
                        order_by: "updated_at"
                    });
                    if(res.status) {
                        setInstances(res.data)
                    }
                })()
            }
        }, []);

        return (
            <Card
                size='small'
                className='home-content-card'
                bordered={false}
            >
                <div className='mb-4 d-flex align-items-center justify-content-between'>
                    <Typography.Title className='home-content-card-title mb-0' level={5}>
                        {t('home.recently_viewed')}
                    </Typography.Title>
                    <Space>
                        <Tooltip placement="top" title={t("common.list")}>
                            <Button
                                onClick={() => setViewType('list')}
                                style={ viewType === 'list' ? {background: 'rgba(0,0,0,0.04)'} : {}}
                                type='text'
                                icon={
                                    <Image
                                        src={ListViewIcon}
                                        preview={false}
                                        width={'1.2em'}
                                    />
                                }
                            />
                        </Tooltip>
                        <Tooltip placement="top" title={t("common.card")}>
                            <Button
                                onClick={() => setViewType('card')}
                                style={ viewType === 'card' ? {background: 'rgba(0,0,0,0.04)'} : {}}
                                type='text'
                                icon={
                                    <Image
                                        src={CardViewIcon}
                                        preview={false}
                                        width={'1.2em'}
                                    />
                                }
                            />
                        </Tooltip>
                    </Space>
                </div>
                {
                    instances.length > 0 ? (
                        viewType === 'card' ? <Row gutter={[16, 12]} style={{marginTop: '12px'}}>
                            {
                                instances.map((instance: any, index: number) => (
                                    <Col sm={12} md={8} lg={6} xxl={4} key={instance.id}>
                                        <div onClick={(e) => {
                                            history.push(`/app/${instance.planning_session.app_id}/instance/${instance.id}/view`);
                                        }}>
                                            <CommonCard
                                                cover={index % 3 === 0 ? HomeCard1 : (index % 3 === 1 ? HomeCard2 : HomeCard3)}
                                                name={instance.planning_session?.name}
                                                icon={FileIcon}
                                                cover_name={instance.name}
                                                name_link={`/app/${instance.planning_session.app_id}/session/${instance.planning_session.id}/view`}
                                                tag={
                                                    i18n.resolvedLanguage === 'en' ?
                                                        (props.apps[instance.planning_session.app_id] || {}).display_name?.en :
                                                        (props.apps[instance.planning_session.app_id] || {}).display_name?.zh
                                                }
                                                tag_color={(props.apps[instance.planning_session.app_id] || {}).color}
                                            />
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row> : <Table
                            tableLayout='fixed'
                            scroll={{ x: 600, scrollToFirstRowOnChange: true }}
                            pagination={false}
                            rowKey={r => r.id}
                            columns={[
                                {
                                    title: t('instance.name'),
                                    key: 'name',
                                    width: 200,
                                    render: (r) => <div className='d-flex flex-row align-items-center'>
                                        <Typography.Paragraph className='table-link mb-0' ellipsis={{
                                            rows: 1,
                                            tooltip: {
                                                title: r.name,
                                                placement: "bottomLeft",
                                                overlayClassName: 'description_tooltip',
                                            }
                                        }}>
                                            <Link to={`/app/${r.planning_session.app_id}/instance/${r.id}/view`}>
                                                {r.name}
                                            </Link>
                                        </Typography.Paragraph>
                                        {
                                            r.is_locked && <Tooltip placement="top" title={t("instance.locked")}>
                                                <Image
                                                    src={LockedIcon}
                                                    preview={false}
                                                    style={{ width: 15, height: 15, marginLeft: 5 }}
                                                />
                                            </Tooltip>
                                        }
                                    </div>
                                },
                                {
                                    title: t('instance.session'),
                                    key: 'session',
                                    width: 200,
                                    render: (r) => <Typography.Paragraph className='table-link mb-0' ellipsis={{
                                        rows: 1,
                                        tooltip: {
                                            title: r.planning_session?.name || '',
                                            placement: "bottomLeft",
                                            overlayClassName: 'description_tooltip',
                                        }
                                    }}>
                                        <Link to={`/app/${r.planning_session.app_id}/session/${r.planning_session.id}/view`}>
                                            {r.planning_session?.name || ''}
                                        </Link>
                                    </Typography.Paragraph>
                                },
                                {
                                    title: t('instance.app'),
                                    key: 'app',
                                    width: 200,
                                    render: (r) => <Typography.Paragraph className='mb-0' ellipsis={{
                                        rows: 1,
                                        tooltip: {
                                            placement: "bottomLeft",
                                            overlayClassName: 'description_tooltip',
                                        }
                                    }}>
                                        {
                                            i18n.resolvedLanguage === 'en' ?
                                                (props.apps[r.planning_session.app_id] || {}).display_name?.en :
                                                (props.apps[r.planning_session.app_id] || {}).display_name?.zh
                                        }
                                    </Typography.Paragraph>
                                }
                            ]}
                            dataSource={instances}
                        />
                    ) : <Empty/>
                }
            </Card>
        )
    }

    const AppCards = () => {
        return (
            <Card size='small' className='home-content-card mb-4' bordered={false}>
                <Typography.Title  className='home-content-card-title mb-4' level={5}>
                    {t('home.all_apps')}
                </Typography.Title>
                {
                    Object.keys(props.apps).length > 0 ? <Row gutter={[16, 12]}>
                        {
                            Object.values(props.apps).map((app: appRunStats, index: number) => (
                                <Col sm={12} md={8} lg={6} xxl={4} key={app.id}>
                                    <Link to={`/app/${app.id}/workbench/active`}>
                                        <Card size='small' bordered={true} className='home-app-card'>
                                            <div className='d-flex align-items-center'>
                                                <Typography.Text
                                                    ellipsis={{
                                                        tooltip: {
                                                            placement: "bottomLeft",
                                                            overlayClassName: 'description_tooltip',
                                                        }
                                                    }}
                                                    className='mx-2'
                                                    style={{flex: 1, color: 'rgba(0,0,0,0.55)', fontWeight: 500}}>
                                                    {`${i18n.resolvedLanguage === 'en' ? app?.display_name.en : app?.display_name.zh}`}
                                                </Typography.Text>
                                                <div className='icon'>
                                                    <Image
                                                        src={[AppIcon2, AppIcon3, AppIcon4, AppIcon5][index % 4]}
                                                        preview={false}
                                                        width={'1.2em'}
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </Link>
                                </Col>
                            ))
                        }
                    </Row> : <Empty description={t('home.app_blank')}>
                        {
                            props.current_workspace_member_type !== 'MEMBER' && <div className='d-flex justify-content-center mb-4'>
                                <Link to={`/workspace_manage`}>
                                    <button className="btn btn-primary">
                                        {t('nav.workspace_app_manage')}
                                    </button>
                                </Link>
                            </div>
                        }
                    </Empty>
                }
            </Card>
        )
    }

    const GuideCards = () => {
        const [collapseActive, setCollapseActive] = useState([]);
        useEffect(() => {
            try {
                const status = window.localStorage.getItem("home-guide")
                if(status !== 'inactivated') {
                    setCollapseActive(['guide'])
                }
            } catch(e){}
        }, []);

        const handleCollapseChange = (key) => {
            try {
                if(key?.length > 0) {
                    window.localStorage.setItem("home-guide", 'activated');
                } else {
                    window.localStorage.setItem("home-guide", 'inactivated');
                }
            } catch(e){}
            setCollapseActive(key)
        }

        const CollapseItems = [
            {
                key: "guide",
                label: <Space size={20} align='baseline'>
                    <Typography.Title className='home-content-card-title mb-0' level={5}>
                        {t('home.welcome')}
                    </Typography.Title>
                    <div className='menu-version' style={{padding: "0"}}>
                        {t('common.version')}: {config.version}
                    </div>
                </Space>,
                children: <div className='d-flex hidden-scrscrollbar guide-cards' style={{ overflowX: 'auto' }}>
                    <div className='item'>
                        <div onClick={() => guideVideoModalRef.current && guideVideoModalRef.current.show()}>
                            <CommonCard
                                top={'8%'}
                                cover={Guide1}
                                name={t('home.guide_video')}
                                icon={AppIcon1}
                            />
                        </div>
                    </div>
                    {
                        Object.values(props.apps).map((app: appRunStats, index: number) => (
                            <div key={app.id} className='item'>
                                <a href={app.doc_url[i18n.resolvedLanguage]} target='_blank' rel="noreferrer">
                                    <CommonCard
                                        top={'8%'}
                                        cover={[Guide2, Guide3, Guide4, Guide5, Guide6][index % 5]}
                                        name={`${
                                            i18n.resolvedLanguage === 'en' ?  app?.display_name.en : app?.display_name.zh
                                        }${t('home.introduction')}`}
                                        icon={[AppIcon2, AppIcon3, AppIcon4, AppIcon5][index % 4]}
                                    />
                                </a>
                            </div>
                        ))
                    }
                </div>
            }
        ]

        return (
            <Collapse
                activeKey={collapseActive}
                onChange={handleCollapseChange}
                className='home-guide mb-4'
                bordered={false}
                expandIconPosition={'end'}
                expandIcon={
                    (panelProps) => <div style={{
                        float: 'right',
                        transition: "all 0.5s ease-out",
                        transform: `rotate(${panelProps?.isActive ? 0.5 : 1}turn)`
                    }}>
                        <Image
                            src={CollapseExpandIcon}
                            preview={false}
                            width={'1.8em'}
                        />
                    </div>
                }
                items={CollapseItems}
            />
        )
    }

    const guideVideoModal = useMemo(() => (
        <GuideVideoModal ref={guideVideoModalRef} />
    ), [])

    return (
        <>
            {guideVideoModal}
            <ManageLayout>
                <GuideCards />
                <AppCards />
                <InstanceCards />
            </ManageLayout>
        </>
    );
};


const mapStateToProps = (store) => ({
  apps: store.account.apps,
  current_workspace_id: store.account.current_workspace?.id,
  current_workspace_member_type: store.account.current_workspace?.member_type,
})

export default connect(mapStateToProps, {})(HomePage);
