import React, {useState, useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import SimpleDevLayout from '../../components/layouts/SimpleDevLayout';
import {Tabs} from 'antd';
import NavHyperLink from '../../components/NavHyperLink';
import InstanceSheet from '../components/InstanceSheet';
import { initApi } from '../../api/BaseApi';

const AppApiService = initApi(process.env.REACT_APP_API_BASE_URL);

const RunInstancesView = (props: React.PropsWithRef<any>) => {
    const { t } = useTranslation();
    const [ tab, setTab ] = useState('input_view');

    const handleTabChange = (key: string) => setTab(key);

    const renderSheetView =useMemo(() => (
        <InstanceSheet
            tab={tab}
            api={AppApiService}
            run_token={props.match.params.run_token}
            active={true}
        />
    ), [
        tab,
        props.match.params.run_token
    ])

    return (
        <SimpleDevLayout>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
                <NavHyperLink showBack={true} routes={[{
                    path: `/`,
                    breadcrumbName: `${props.match.params.run_token || ''}`,
                }]} />
            </div>
            <Tabs
                activeKey={tab || 'input_view'}
                onChange={handleTabChange}
                items={[{
                    label: t('instance.input_view'),
                    key: 'input_view',
                }, {
                    label: t('instance.output_view'),
                    key: 'output_view'
                }]}
            />
            <div style={{position: 'relative'}}>
                {renderSheetView}
            </div>
        </SimpleDevLayout>
    );
};

export default RunInstancesView;
