import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { RunInstance } from '../../interfaces/runInstance';
import RunInstanceForm, { RunInstanceFormAction } from './RunInstanceForm';

type Props = {
    app_id: string;
    callback?: () => void;
}

const RunInstanceCloneModal = forwardRef((props:Props, ref) => {
    const { t } = useTranslation();
    const [showItem, setShowItem] = useState<RunInstance | null>();
    const [action, setAction] = useState<RunInstanceFormAction>();
    const formRef: any = useRef();

    useImperativeHandle(ref, () => ({
        show: (record: RunInstance, action: RunInstanceFormAction) => {
            setAction(action)
            setShowItem(record)
            formRef?.current?.reset && formRef?.current?.reset({
                ...record,
                name: `${record?.name}-${t('instance.action.copy')}`,
                description: `${t('instance.action.copy')}: ${record?.description}`
            })
        }
    }))

    const handleCancel = () => {
        formRef?.current?.reset && formRef?.current?.reset()
        setShowItem(null)
    }

    const handleSubmit = () => {
        formRef?.current?.submit && formRef?.current?.submit()
    }

    return(
        <Modal
            centered
            forceRender={true}
            title={`${t('instance.action.copy')}-${showItem?.name}`}
            open={!!showItem}
            onCancel={handleCancel}
            onOk={handleSubmit}
            cancelText={t('common.cancel')}
            okText={t('instance.action.copy')}
        >
            <RunInstanceForm
                ref={formRef}
                app_id={props.app_id}
                action={action}
                hiddenSubmit={true}
                default={{
                    id: showItem!?.id,
                    session_id: showItem!?.planning_session_id
                }}
                successCallback={(data:RunInstance) => {
                    handleCancel();
                    props?.callback && props?.callback()
                }}
            />
        </Modal>
    )
})

export default RunInstanceCloneModal;
