import React from 'react';
import PlanningSessions from './procurement/PlanningSessions';
import { BrowserRouter, Route, Router } from "react-router-dom";

import Loading from './procurement/components/Loading';
import history from "./utils/history";
import FlowAssistant from './components/FlowAssistant';
import CommonErrorView from "./components/CommonErrorView";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";

import PlanningSessionsView from './procurement/PlanningSessionsView';
import RunInstancesView from "./procurement/RunInstancesView";
import HomePage from "./procurement/HomePage";
import ExecutionPage from "./procurement/ExecutionPage";
import Profile from './procurement/components/Profile';
import ExecutionLogs from "./procurement/ExecutionLogs";
import WorkspaceManage from './procurement/WorkspaceManage';
import RunInstancesComparePage from './procurement/RunInstancesComparePage';
import NotificationPage from './procurement/NotificationPage';
import SystemManage from './procurement/SystemManage/index';

import SimpleDevPlanningSessionsView from './procurement/SimpleDev/PlanningSessionsView';
import SimpleDevRunInstancesView from './procurement/SimpleDev/RunInstancesView';

import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';
import { useTranslation } from 'react-i18next';

import useInitApp from './utils/useInitApp';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import 'moment/locale/zh-cn';
import 'dayjs/locale/zh-cn';

initFontAwesome();

dayjs.extend(weekday)
dayjs.extend(localeData)

const ThemeConfig = {
  token: {
    colorPrimary: "#2A61FF",
    colorLink: "#2A61FF",
    colorError: "#FF4D4F",
    colorSuccess: "#52C41A",
    colorTextBase: "rgba(0,0,0,0.85)",
    colorWarning: "#FAAD14",
    borderRadius: 4,
    colorPrimaryHover: "#4978FF"
  },
};

function App() {
  const { i18n } = useTranslation();
  const initAppStatus = useInitApp();

  if(process.env.REACT_APP_MODE === 'simple-dev') {
    return (
      <ConfigProvider theme={ThemeConfig} locale={i18n.resolvedLanguage === 'en' ? enUS : zhCN}>
        <Router history={history}>
          <div className="App">
            <BrowserRouter>
              <Route path='/' exact component={SimpleDevPlanningSessionsView} />
              <Route path='/run_instances/:run_token/view' exact component={SimpleDevRunInstancesView} />
            </BrowserRouter>
          </div>
        </Router>
      </ConfigProvider>
    )
  }

  if (initAppStatus.status === 'error') {
    return <CommonErrorView />;
  }

  if (initAppStatus.status === 'loading') {
    return <Loading />;
  }

  return (
    <ConfigProvider theme={ThemeConfig} locale={i18n.resolvedLanguage === 'en' ? enUS : zhCN}>
      <FlowAssistant />
      <Router history={history}>
        <div className="App">
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Route path='/' exact component={HomePage} />
            <Route path='/app/:app_id' exact component={PlanningSessions} />
            <Route path='/app/:app_id/workbench/:status' exact component={PlanningSessions} />
            <Route path='/app/:app_id/session/:session_token/view' exact component={PlanningSessionsView} />
            <Route path='/app/:app_id/session/:session_token/view/:status' exact component={PlanningSessionsView} />
            <Route path='/app/:app_id/instance/:run_token/view' exact component={RunInstancesView} />
            <Route path='/app/:app_id/instance/:run_token/view/:status' exact component={RunInstancesView} />
            <Route path='/app/:app_id/compare/:instances/:tables' exact component={RunInstancesComparePage} />

            <Route path='/app/:app_id/executions' exact component={ExecutionPage} />
            <Route path='/app/:app_id/executions/:process_id/logs' exact component={ExecutionLogs} />
            <Route path="/profile" exact component={Profile} />

            <Route path='/workspace_manage' exact component={WorkspaceManage} />

            <Route path='/system_manage' component={SystemManage} />

            <Route path='/app/:app_id/notification' exact component={NotificationPage} />

          </BrowserRouter>
        </div>
      </Router>
    </ConfigProvider>
  );
}

export default App;
