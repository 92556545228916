import React, {PropsWithRef, useEffect, useState, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {Tabs, Spin, Tooltip, Modal, Image} from 'antd';

import {PlanningSession} from "../interfaces/planningSession";
import ManageLayout from '../components/layouts/ManageLayout';
import NavHyperLink from '../components/NavHyperLink';
import SessionForm, { SessionFormAction } from './components/SessionForm';
import { getSessionApi } from '../api/ServerApi';
import RunInstanceList from './components/RunInstanceList';
import EditIcon from'../common/images/icons/edit-icon.png';

const PlanningSessionsView = (props: PropsWithRef<any>) => {
    const { t } = useTranslation();
    const [planningSession, setPlanningSession] = useState<PlanningSession>({} as PlanningSession);

    useEffect(() => {
        getSessionApi({
            session_id: props.match.params.session_token,
        }).then((res) => {
            if(res.status) {
                setPlanningSession(res.data);
            } else {
                setPlanningSession({id: null});
            }
        });
    }, [props.match.params]);


    const PlanningSessionInfoEditModal = () => {
        const [show, setShow] = useState(false);
        const formRef:any = useRef();

        useEffect(() => {
            formRef!.current!.reset(planningSession);
        }, []);

        const handleCancel = () => {
            setShow(false);
            formRef!.current!.reset(planningSession);
        }

        const handleSubmit = () => {
            formRef?.current?.submit();
            setShow(false)
        }

        const handleClick = () => {
            setShow(true);
        }
        return (
            <div>
                <Tooltip title={t('session.action.info_update')} style={{fontSize: '16px'}}>
                    <Image
                        src={EditIcon}
                        preview={false}
                        style={{ width: 18, height: 18, marginLeft: 10, cursor: "pointer" }}
                        onClick={handleClick}
                    />
                </Tooltip>
                <Modal
                    centered
                    forceRender={true}
                    title={t('session.action.info_update')}
                    open={!!show}
                    onCancel={handleCancel}
                    onOk={handleSubmit}
                    cancelText={t('common.cancel')}
                    okText={t('common.update')}
                    okButtonProps={{ style: !!planningSession.active?{}:{ display: 'none' } }}
                >
                    <SessionForm
                        ref={formRef}
                        app_id={props.match.params.app_id}
                        {...(
                            !!planningSession.active && props.match.params?.status !== 'archive' ? {
                                action: SessionFormAction.Update,
                                successCallback: (data:PlanningSession) => {
                                    setPlanningSession((prev:PlanningSession) => {
                                        return {...prev, ...data}
                                    })
                                },
                                hiddenSubmit: true,
                            } : { disabled: true }
                        )}
                    />
                </Modal>
            </div>
        )
    }

    return (
        <ManageLayout>
            {
                !!planningSession.id &&
                props.match.params.app_id === planningSession?.app_id &&
                props.current_workspace_id === planningSession?.workspace_id ? <>
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                        <NavHyperLink showBack={true} routes={
                            !!planningSession.active && props.match.params?.status !== 'archive' ? [
                                {
                                    path: `/app/${props.match.params.app_id}/workbench/active`,
                                    breadcrumbName: t('nav.workbench'),
                                }, {
                                    path: '#',
                                    breadcrumbName: `${planningSession?.name || ''}`,
                                }
                            ] : [
                                {
                                    path: `/app/${props.match.params.app_id}/workbench/archive`,
                                    breadcrumbName: t('common.archive'),
                                }, {
                                    path: '#',
                                    breadcrumbName: `${planningSession?.name || ''}`,
                                }
                            ]
                        }  edit_modal={
                            <PlanningSessionInfoEditModal />
                        }/>
                    </div>
                    <div className='page-layout-content-content'>
                        <Tabs
                            destroyInactiveTabPane={true}
                            items={[
                                {
                                    key: 'all_run_instances',
                                    label: t('session.instance_list'),
                                    children: <RunInstanceList
                                        app_id={props.match.params.app_id}
                                        session_id={props.match.params.session_token}
                                        active={!!planningSession.active && props.match.params?.status !== 'archive'}
                                        search={true}
                                        archive={!!planningSession.active && props.match.params?.status !== 'archive'}
                                        unarchive={props.match.params?.status === 'archive'}
                                        instance_create={!!planningSession.active && props.match.params?.status !== 'archive'}
                                        clone={!!planningSession.active && props.match.params?.status !== 'archive'}
                                    />
                                }
                            ]}
                        />
                    </div>
                </> : <div className='d-flex justify-content-center my-5'>
                    { planningSession.id === undefined ? <Spin/> : t('common.no_permission') }
                </div>
            }
        </ManageLayout>
    );
};

const mapStateToProps = (store) => ({
  apps: store.account.apps,
  current_workspace_id: store.account.current_workspace?.id,
})

export default connect(mapStateToProps, {})(PlanningSessionsView);
