import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SystemManageLayout from '../../components/layouts/SystemManageLayout';

import Dashboard from './Dashboard';
import AppList from './AppList';
import AuthManage from './AuthManage';
import AppPermission from './AppPermission';
import InstanceData from './InstanceData';
import UserList from './UserList';
import UseDocumentTitle from '../components/UseDocumentTitle';
import { useTranslation } from 'react-i18next';

const SystemManage = () => {
    const {t} = useTranslation();
    UseDocumentTitle(t('system_manage_title'));

    return (
        <SystemManageLayout>
            <Switch>
                <Route path='/system_manage' exact component={Dashboard} />
                <Route path='/system_manage/app_list' exact component={AppList} />
                <Route path='/system_manage/auth_manage' exact component={AuthManage} />
                <Route path='/system_manage/app_permission' exact component={AppPermission} />
                <Route path='/system_manage/instance_data' exact component={InstanceData} />
                <Route path='/system_manage/user_list' exact component={UserList} />
            </Switch>
        </SystemManageLayout>
    );
};

export default SystemManage;
