import { useRef } from "react";
import { Socket, io } from "socket.io-client";
import { User } from "../interfaces/user";

let notificationInfo: any;

const useNovu = () => {
  const socket = useRef<Socket>();

  const init = (user: User) => {
    notificationInfo = user.notification_info;
  };

  const isAvailable = () => {
    return !!notificationInfo && notificationInfo.novu_host && notificationInfo.token;
  };

  const callApi = async (path: string, method: string, body?: object) => {
    const prefix = `${notificationInfo.novu_host}/v1`;
    let url = `${prefix}/${path}`;
    const response = await fetch(url, {
      method: method,
      headers: {
        Authorization: `Bearer ${notificationInfo.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  };

  const initSession = async (callbacks: {
    onInited?: () => void;
    onUnreadCountChanged?: (data: any) => void;
  }) => {
    const json = await callApi("widgets/session/initialize", "post", {
      applicationIdentifier: notificationInfo.novu_application_identifier,
      subscriberId: notificationInfo.novu_subscriber_id,
      hmacHash: null,
    });
    socket.current = io(notificationInfo.novu_socket_url, {
      reconnectionDelayMax: 10000,
      transports: ["websocket"],
      query: {
        token: `${json.data.token}`,
      },
    });
    notificationInfo.token = json.data.token;
    callbacks.onInited && callbacks.onInited();
    socket.current.on("unread_count_changed", (data) => {
      callbacks.onUnreadCountChanged && callbacks.onUnreadCountChanged(data);
    });
  };

  const getMessages = async (params: {
    page?: number;
    limit?: number;
    read?: boolean;
    payload?: object;
  }) => {
    const queryParams = new URLSearchParams();
    if (params.page) queryParams.append("page", (params.page - 1).toString());
    if (params.limit) queryParams.append("limit", params.limit.toString());
    if (params.read !== undefined)
      queryParams.append("read", params.read.toString());
    if (params.payload)
      queryParams.append(
        "payload",
        `${btoa(unescape(encodeURIComponent(JSON.stringify(params.payload))))}`
      );

    const json = await callApi(
      `widgets/notifications/feed/?${queryParams.toString()}`,
      "get"
    );

    return json;
  };

  const getMessageCount = async (params: {
    read?: boolean;
    payload?: object;
  }) => {
    const queryParams = new URLSearchParams();

    if (params.read !== undefined)
      queryParams.append("read", params.read.toString());
    if (params.payload)
      queryParams.append(
        "payload",
        `${btoa(unescape(encodeURIComponent(JSON.stringify(params.payload))))}`
      );

    const json = await callApi(
      `widgets/notifications/unread/?${queryParams.toString()}`,
      "get"
    );

    return json;
  };

  const markMessaegsAsRead = async (messageId: string) => {
    const json = await callApi(`widgets/messages/markAs`, "post", {
      messageId,
      mark: {
        read: true,
      },
    });

    return json;
  };

  const markAllMessagesAsRead = async () => {
    const json = await callApi(`widgets/messages/read`, "post");

    return json;
  };

  return {
    socket,
    init,
    isAvailable,
    initSession,
    getMessages,
    getMessageCount,
    markMessaegsAsRead,
    markAllMessagesAsRead,
  };
};

export default useNovu;
